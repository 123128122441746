import React from "react";
import "./styles.css";

import Logo from "../../assets/logos-topo.png";

const Header = () => {
  return (
    <header>
      <div id="header">
        <div className="container">
          <figure>
            <img src={Logo} alt="Logo" />
          </figure>
        </div>
      </div>

      <div id="cta">
        <div className="container">
          <a href="tel:37191827" className="btn">
            LIGUE AGORA (11) 3719-1827
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
