import React from "react";
import "./styles.css";

import img from "../../assets/sobre-a-l7.png";
import img2 from "../../assets/img-sobre-a-l7.png";

const About = () => {
  return (
    <main id="about">
      <div className="container">
        <div className="column1">
          <figure>
            <img src={img} alt="Peças" />
          </figure>

          <p>Ligue agora e solicite um orçamento</p>

          <a href="tel:37191827" className="btn">
            LIGUE AGORA (11) 3719-1827
          </a>
        </div>

        <div className="column2">
          <figure>
            <img src={img2} alt="Peças" />
          </figure>
        </div>
      </div>
    </main>
  );
};

export default About;
