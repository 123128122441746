import React from "react";
import "./styles.css";

const Buy = () => {
  return (
    <main id="buy">
      <div className="container">
        <a href="tel:37191827" className="btn">
          LIGUE AGORA (11) 3719-1827
        </a>
      </div>
    </main>
  );
};

export default Buy;
